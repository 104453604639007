
import {defineComponent, reactive, toRefs, onBeforeMount, onMounted} from 'vue'
import axios from '@/api/http.js';
import {ElMessageBox} from 'element-plus';
import {ElLoading} from 'element-plus';

export default defineComponent({
  name: '',
  setup() {
    const data = reactive({
      links: [
        {
          label: 'Learn more about Aruba’s certification and training program',
          link: 'https://www.arubanetworks.com/support-services/training-services/'
        },
        {
          label: 'Purchase self-directed labs with Aruba Education Services',
          link: 'https://inter.viewcentral.com/events/cust/search_results.aspx?keyword=&eventMonthYear=&location_group=Online+Labs&event_address_id=&language_cat=&event_id=&postingForm=default.aspx&cid=aruba&pid=1&lid=15&cart_currency_code=&payment_type=&orderby_location=&orderby_date=&newRegistration=&bundle_location_group=&errmsg='
        },
        {
          label: 'Purchase certification study-guides on HPE Press',
          link: 'https://hpepress.hpe.com/catalog/Aruba+Networking-98'
        },
        {
          label: 'Join the Aruba Airheads Learning Community',
          link: 'https://community.arubanetworks.com/home'
        },

      ]
    })

    const refData = toRefs(data);
    const openLink = (url) => {
      window.open(url);
    }
    return {
      ...refData,
      openLink
    }

  }
});
